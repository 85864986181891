@font-face {
  font-family: 'LeagueSpartan-Black';
  src: url('./LeagueSpartan-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-Bold';
  src: url('./LeagueSpartan-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-ExtraBold';
  src: url('./LeagueSpartan-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-ExtraLight';
  src: url('./LeagueSpartan-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-Light';
  src: url('./LeagueSpartan-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-Medium';
  src: url('./LeagueSpartan-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-Regular';
  src: url('./LeagueSpartan-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-SemiBold';
  src: url('./LeagueSpartan-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'LeagueSpartan-Thin';
  src: url('./LeagueSpartan-Thin.ttf') format('truetype');
}

.font-ls-black {
  font-family: 'LeagueSpartan-Black';
}

.font-ls-bold {
  font-family: 'LeagueSpartan-Bold';
}

.font-ls-extrabold {
  font-family: 'LeagueSpartan-ExtraBold';
}

.font-ls-extralight {
  font-family: 'LeagueSpartan-ExtraLight';
}

.font-ls-light {
  font-family: 'LeagueSpartan-Light';
}

.font-ls-medium {
  font-family: 'LeagueSpartan-Medium';
}

.font-ls-regular {
  font-family: 'LeagueSpartan-Regular';
}

.font-ls-semibold {
  font-family: 'LeagueSpartan-SemiBold';
}

.font-ls-thin {
  font-family: 'LeagueSpartan-Thin';
}